<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/orderInfo' }">订单列表</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="shebei">基本信息</div>
        <el-form
            :model="upData"
            label-position="rihgt"
            label-width="140px"
            class="content_li-form content_li-padding"
        >
          <el-form-item label="用户昵称：">{{ upData.nickName }}</el-form-item>
          <el-form-item label="订单编号：">{{ upData.orderNumber }}</el-form-item>
          <el-form-item label="订单状态：">
            <el-tag effect="light" :color="upData.orderStatus | getOrderStatusType">{{ upData.orderStatus | getOrderStatus }}</el-tag>
          </el-form-item>
          <el-form-item label="微信支付订单号：">{{ upData.outTradeNo }}</el-form-item>
          <el-form-item label="订单总金额：">{{
              upData.totalPrice ? (upData.totalPrice.toFixed(2)) / 100 : '0.00'
            }}元
          </el-form-item>
          <el-form-item label="所需运费：">{{
              upData.maxFreight ? (upData.maxFreight.toFixed(2)) / 100 : '0.00'
            }}元
          </el-form-item>
          <el-form-item label="支付金额：">{{
              upData.payPrice ? (upData.payPrice.toFixed(2)) / 100 : '0.00'
            }}元
          </el-form-item>
          <el-form-item label="购买总数：">{{ upData.totalCount }}</el-form-item>
          <el-form-item label="使用积分：">{{ upData.payIntegral ? `${upData.payIntegral}分` : '0分' }}</el-form-item>
          <el-form-item label="收件人：">{{ upData.recipient }}</el-form-item>
          <el-form-item label="收件人电话：">{{ upData.recipientPhone }}</el-form-item>
          <el-form-item label="收件地址：">{{ upData.recipientAddress }}</el-form-item>
          <el-form-item label="支付订单号：">{{ upData.tradeNo }}</el-form-item>
          <el-form-item label="快递公司：">{{ upData.expressName }}</el-form-item>
          <el-form-item label="快递单号：">{{ upData.expressNumber }}</el-form-item>
          <el-form-item label="留言：">{{ upData.remarks }}</el-form-item>
          <el-form-item label="商品图片：" v-if="false">
            <div class="flexRow1">
              <div class="up-image">
                <div v-for="(item, index) in upData.attrsAssociateImage" class="image-list" :key="index">
                  <img @click="handleSee(item)" :src="item" alt/>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="shebei">商品清单</div>
        <div class="content_li-padding">
          <el-table
              border
              :data="upData.orderCommodityInfos"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" label="订单ID" prop="orderNumber" :min-width="120"></el-table-column>
            <el-table-column align="center" label="商品图片">
              <template slot-scope="scope">
                <img @click="handleSee(scope.row.attrsAssociateImage)" class="icon-img"
                     :src="scope.row.attrsAssociateImage"/>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商品名称" prop="commodityName"></el-table-column>
            <el-table-column align="center" label="购买规格" prop="attributesName"></el-table-column>
            <el-table-column align="center" label="购买时间" prop="createDate"></el-table-column>
            <el-table-column align="center" label="购买价格">
              <template slot-scope="scope">{{ (scope.row.sellingPrice / 100).toFixed(2) }}元</template>
            </el-table-column>
            <el-table-column align="center" label="购买数量" prop="payCount"></el-table-column>
            <el-table-column align="center" label="评价状态" prop="payCount">
              <template slot-scope="scope">{{ scope.row.evaStatus| getEvaStatus }}</template>
            </el-table-column>


          </el-table>
        </div>
      </div>
    </div>

    <div class="footer-toolbar">
      <el-button @click="$router.go(-1)" type="primary">返回</el-button>
    </div>

    <picture-dialog :url="dialogImageUrl" :visible.sync="dialogVisible"/>

  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    return {
      upData: {},
      orderId: '',
      dialogImageUrl: '',
      dialogVisible: false,
      orderStatus: [
        {label: '待付款', value: 0, color: '#409EFF'},
        {label: '待发货', value: 1, color: '#b05bff'},
        {label: '待收货', value: 3, color: '#F56C6C'},
        {label: '待评价', value: 4, color: '#67C23A'},
        {label: '已评价', value: 5, color: '#f988ff'},
        {label: '超时自动取消', value: 6, color: '#909399'},
        {label: '用户主动取消', value: 7, color: '#aba2d4'},
      ],
      evaStatus: [
        {label: '待评价', value: 1},
        {label: '已评价', value: 2}
      ],
    };
  },
  filters: {
    getOrderStatus(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getOrderStatusType(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].color;
      }
    },
    getEvaStatus(value) {
      const data = _this.evaStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.getData(this.orderId);
    }
  },
  methods: {
    //根据ID来查询订单信息
    getData(id) {
      let _this = this;
      _this.$api.getOrderInfoById({id}).then(res => {
        if (res.data.code == 200) {
          _this.upData = res.data.data;
        }
      });
    },
    handleSee(url) {
      let _this = this;
      _this.dialogImageUrl = url;
      _this.dialogVisible = true;
    },
  }
};
</script>
<style scoped lang="less">
.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.content_li {
  padding: 0;
}

.content_li-padding {
  padding: 30px;
  box-sizing: border-box;
}

.content_li-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 2;
}

.el-form-item {
  width: 50%;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}
/deep/ .el-tag {
  color: #fff !important;
  border: none;
}
</style>
